/* non-critical.scss - to be asynchronously loaded */
$jacket: non-critical;
@import "main";
.lazy-hidden {
  opacity: 0;
}
.lazy-loaded {
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
  opacity: 1;
}
