@include jacket(non-critical) {
  // MOBILE
  @media screen and (max-width: $navbar-breakpoint) {
    section.projects {
      .section-content {
        ul.project-list {
          .project-wrapper {
            .project-desc {
              display: grid;
            }
          }
        }
      }
    }
  }

  body.day-mode {
    section.projects {
      .section-content {
        ul.project-list {
          .project-wrapper {
            border-bottom: 1px solid rgba($secondary-color, 0.5);

            .image {
              .overlay-content {
                a {
                  color: $bg-color;
                  border: 1px solid $secondary-color;

                  &:hover {
                    background: rgba(246, 244, 246, 0.7);
                    border: 1px solid rgba(238, 233, 237, 0.8);
                    color: $primary-color;
                    // background: $secondary-color;
                    // border: 1px solid $primary-color;
                    // border: 1px solid $highlight-color;
                    // color: $highlight-color;
                  }
                }
              }
            }

            .project-desc {
              .project-subtext {
                color: rgba($primary-color, 0.7);
              }
            }
          }
        }
      }
    }

    .tools i .text {
      color: $primary-color;
    }
  }

  body.night-mode {
    section.projects {
      .section-content {
        ul.project-list {
          .project-wrapper {
            border-bottom: 1px solid rgba($night-primary-color, 0.3);

            .image {
              .overlay-content {
                a {
                  color: $bg-color;
                  border: 1px solid $secondary-color;
                  -webkit-transition: all 0.2s ease-in-out 0s;
                  -moz-transition: all 0.2s ease-in-out 0s;
                  transition: all 0.2s ease-in-out 0s;

                  &:hover {
                    color: $night-primary-color;
                    background: rgba(72, 86, 101, 0.7);
                    border: 1px solid rgba(105, 116, 129, 0.8);
                    // border: 1px solid $night-highlight-color;
                    // color: $night-highlight-color;
                  }
                }
              }
            }

            .project-desc {
              .project-subtext {
                color: rgba($night-primary-color, 0.7);
              }
            }
          }
        }
      }
    }

    .tools i .text {
      color: $night-primary-color;
    }
  }

  section.projects {
    .section-content {
      ul.project-list {
        list-style: none;
        padding: 0;
        margin-bottom: 3em;

        .project-wrapper {
          padding: 2em 0 1em 0;
          display: inline-block;

          .image {
            position: relative;
            margin-bottom: 2em;
            max-height: 260px;
            max-width: 900px;
            overflow: hidden;

            // MOBILE
            @media screen and (max-width: $navbar-breakpoint) {
              &:hover .overlay-content,
              &:focus .overlay-content {
                top: 50%;
                left: 50%;
                display: grid;
                visibility: visible;
              }

              &:hover .overlay,
              &:focus .overlay {
                opacity: 1;
                // backdrop-filter: blur(7px);
              }
            }

            // DESKTOP
            @media screen and (min-width: $navbar-breakpoint) {
              &:hover .overlay-content,
              &:focus .overlay-content {
                top: 50%;
                left: 50%;
                visibility: visible;
                opacity: 1;
              }

              &:hover .overlay,
              &:focus .overlay {
                opacity: 1;
                // backdrop-filter: blur(7px);
              }
            }

            .overlay {
              background: rgba(40, 40, 43, 0.8);
              position: absolute;
              height: 100%;
              width: 100%;
              left: 0;
              top: 0;
              bottom: 0;
              right: 0;
              opacity: 0;
              -webkit-transition: all 0.2s ease-in-out 0s;
              -moz-transition: all 0.2s ease-in-out 0s;
              transition: all 0.2s ease-in-out 0s;
            }

            // MOBILE
            @media screen and (max-width: $navbar-breakpoint) {
              .overlay-content {
                display: none;
                visibility: hidden;
              }
            }

            // DESKTOP
            @media screen and (min-width: $navbar-breakpoint) {
              .overlay-content {
                visibility: hidden;
                opacity: 0;
              }
            }

            .overlay-content {
              width: 100%;
              position: absolute;
              text-align: center;
              padding-left: 1em;
              padding-right: 1em;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%);
              -moz-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              -webkit-transition: all 0.3s ease-in-out 0s;
              -moz-transition: all 0.3s ease-in-out 0s;
              transition: all 0.3s ease-in-out 0s;
              top: 80%;

              span {
                margin: 6px 0;
              }

              a {
                padding: 8px 24px;
                margin: 0 8px;
                background: transparent;
                font-weight: 300;
                font-style: normal;

                &:hover {
                  text-decoration: none;
                }
              }

              .github-btn .private-code {
                color: $bg-color;
              }

              .github-btn,
              .doc-btn {
                &:hover .arrow {
                  -webkit-transform: translateX(4px);
                  transform: translateX(4px);
                }

                .arrow {
                  padding-left: 5px;
                  -webkit-transform: translateZ(0);
                  transform: translateZ(0);
                  -webkit-transition-duration: 0.2s;
                  transition-duration: 0.2s;
                  -webkit-transition-property: transform;
                  transition-property: transform;
                  -webkit-transition-timing-function: ease-out;
                  transition-timing-function: ease-out;
                  display: inline-block;
                  font-style: normal;
                }
              }
            }

            img {
              width: 100%;
              height: 100%;
            }
          }

          .project-desc {
            .project-title {
              font-size: 24px;
              margin: 10px 1em 10px 0;
              font-style: normal;
              font-weight: bold;
              font-family: "Libre Baskerville", Georgia, "Times New Roman",
                Times, serif;
              letter-spacing: -1px;

              a {
                font-style: normal;
              }
            }

            .project-subtext {
              font-size: 18px;
              font-weight: normal;
            }
          }

          .full-desc {
            margin-top: 0.5em;
          }

          .tools {
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .tools {
    margin-top: 1em;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    i {
      font-size: 3em;
      // margin-right: 8px;
      display: inline-grid;
      text-align: center;
      // min-width: 10%;
      padding: 0.2rem 0.2rem 0.5rem;
      width: 70px;

      &:hover .text {
        transition-duration: 0.2s;
        opacity: 1;
        font-family: "Inconsolata", "Courier New", Courier, monospace;
      }

      .text {
        font-size: 14px;
        opacity: 0;
        padding-top: 0.5em;
        font-family: "Inconsolata", "Courier New", Courier, monospace;
      }
    }
  }
}
