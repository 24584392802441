@include jacket(non-critical) {
  // MOBILE
  @media screen and (max-width: $navbar-breakpoint) {
    .experience {
      .company-info {
        display: grid;
      }
    }
  }

  body.day-mode {
    .experience {
      .section-content {
        ul.company-list {
          .company-wrapper {
            border-bottom: 1px solid rgba($secondary-color, 0.5);

            .timeline {
              color: rgba($primary-color, 1);
            }

            .company-info {
              .company-name,
              a {
                color: rgba($primary-color, 0.9);
              }
            }

            .company-desc {
              color: rgba($primary-color, 0.7);
            }
          }
        }
      }
    }
  }

  body.night-mode {
    .experience {
      .section-content {
        ul.company-list {
          .company-wrapper {
            border-bottom: 1px solid rgba($secondary-color, 0.5);

            .timeline {
              color: rgba($night-primary-color, 1);
            }

            .company-info {
              .company-name,
              a {
                color: rgba($night-primary-color, 0.9);
              }
            }

            .company-desc {
              color: rgba($night-primary-color, 0.7);
            }
          }
        }
      }
    }
  }

  .experience {
    .section-content {
      .resume-section {
        margin-bottom: 3em;

        .resume-header {
          font-size: 24px;
          font-weight: normal;
        }
      }

      ul.company-list {
        list-style: none;
        padding: 0;
        margin-bottom: 1em;

        .company-wrapper {
          margin-bottom: 2em;
          display: inline-block;

          ul {
            list-style: none;

            &.full-desc {
              margin-left: -1rem;
            }

            li {
              align-items: baseline;
            }

            ::marker {
              content: "> ";
              font-weight: bold;
              font-family: "Courier New", Courier, monospace;
              margin-right: 8px;
            }
          }

          .timeline {
            // text-transform: uppercase;
            // font-size: .8em;
            font-family: Inconsolata, monospace;
            // font-family: 'Libre Baskerville', Georgia, 'Times New Roman', Times, serif;
          }

          .company-info {
            margin: 10px auto;
            font-size: 24px;
            font-family: "Libre Baskerville", Georgia, "Times New Roman", Times,
              serif;
            letter-spacing: -1px;

            .company-name {
              font-style: italic;
              font-weight: bold;
              letter-spacing: -0.5px;

              &:before {
                content: "@ ";
                font-style: normal;
              }
            }

            .job-title {
              font-weight: bold;
              margin-right: 1em;
            }
          }

          .company-desc {
            font-size: 18px;
          }

          .full-desc {
            margin-top: 1em;
            margin-bottom: 1em;
          }

          .tech-stack {
            margin: 0.5em 0;

            .stack {
              font-size: 1.05em;
              font-weight: bold;
              font-family: "Libre Baskerville", Georgia, "Times New Roman",
                Times, serif;
              letter-spacing: -1px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
