@include jacket(critical) {
  body.day-mode {
    header {
      a,
      a:active,
      a:focus {
        color: $primary-color;
        text-decoration: none;

        &:visited {
          color: $primary-color;
        }
      }

      .navbar-container {
        color: $primary-color;

        .mobile-logo {
          border: double 5px $primary-color;
          color: $primary-color;
        }
      }
    }
  }

  body.night-mode {
    header {
      a,
      a:active,
      a:focus {
        color: $night-primary-color;
        text-decoration: none;

        &:visited {
          color: $night-primary-color;
        }
      }

      .navbar-container {
        color: $night-primary-color;
        .mobile-logo {
          border: double 5px $night-primary-color;
          color: $night-primary-color;
        }
      }
    }
  }

  .navbar-container {
    font-family: "Libre Baskerville", Georgia, "Times New Roman", Times, serif;
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding: 1.5em 0;
    display: flex;
    justify-content: space-between;
    display: flex;
    margin: auto 0;
    align-items: center;

    .mobile-logo {
      display: inline;
      font-size: 2em;
      letter-spacing: -4px;
      /* padding-right: 4px; */
      margin: auto 0;
      padding: 4px 8.3px 4px 4.3px;

      &:before {
        content: "Kr.";
      }
    }
    .name {
      cursor: default;
      font-size: 1.8em;
      font-weight: bold;
    }
  }

  // DESKTOP
  @media screen and (min-width: $navbar-breakpoint) {
    body.day-mode {
      .navbar-container {
        .menu {
          li.nav-item {
            a:hover {
              background-image: linear-gradient(
                120deg,
                $gradient-left 0%,
                $gradient-right 100%
              );
            }
          }
        }
      }
    }

    body.night-mode {
      .navbar-container {
        .menu {
          li.nav-item {
            a:hover {
              background-image: linear-gradient(
                120deg,
                $night-gradient-left 0%,
                $night-gradient-right 100%
              );
            }
          }
        }
      }
    }

    .navbar-container {
      padding-left: 2.2em;
      padding-right: 2.2em;
      margin: 0 auto;
    }

    .menu {
      margin: auto 0 auto auto;
      padding: 0;
      display: inline-table;

      li.nav-item {
        float: left;
        margin-left: 1em;
        list-style: none;
        font-size: 1.1em;

        a {
          background-size: 0% 0.3em;
          transition: background-size 0.25s ease-in;

          &:hover {
            text-decoration: none;
            background-repeat: no-repeat;
            background-size: 100% 0.3em;
            background-position: 0 88%;
          }
        }
      }
    }

    .mobile-hamburger {
      display: none;
    }

    .dropdown-menu {
      display: none;
    }
  }

  // MOBILE
  @media screen and (max-width: $navbar-breakpoint) {
    body.day-mode {
      .toggle-icon {
        border-bottom: 4px solid $primary-color;

        &:before,
        &:after {
          border-top: 4px solid $primary-color;
        }
      }
    }

    body.night-mode {
      .toggle-icon {
        border-bottom: 4px solid $night-primary-color;

        &:before,
        &:after {
          border-top: 4px solid $night-primary-color;
        }
      }
    }

    .navbar-container {
      padding: 1.5em 1em;

      .name {
        display: none;
      }
    }

    .menu {
      display: none;
    }

    .dropdown-menu {
      .mobile-menu {
        padding: 0 1em;
        margin: 0;

        li.mobile-nav-item {
          list-style: none;
          font-size: 1.5em;
          padding: 0.5em 0;

          a:focus {
            background-size: 100% 88%;
          }
        }
        li:first-child {
          padding-top: 0;
        }
      }
    }

    .mobile-hamburger {
      margin-top: -8px;

      .nav-toggle {
        width: 30px;
        height: 40px;
        display: block;
        position: relative;
        cursor: pointer;
        display: inline-block;

        .toggle-icon {
          transition-delay: 0.125s;
          transition-property: transform, border-color;
          transition-duration: 0.25s;
          transition-timing-function: cubic-bezier(0.5, -0.5, 0.5, 1.5);
          padding-top: 22px;
          display: block;

          &:before,
          &:after {
            transition-delay: 0s;
            transform-origin: center;
            content: "";
            position: absolute;
            left: 0;
            right: 0;
          }

          &:before {
            top: 12px;
          }

          &:after {
            bottom: 4px;
          }
        }

        .toggle-label {
          text-align: center;
          display: block;
          position: relative;
          left: -50px;
          bottom: 14px;
        }
      }
    }
  }
}

@include jacket(non-critical) {
  .toggle-icon,
  .toggle-icon:before,
  .toggle-icon:after {
    transition-property: transform, border-color;
    transition-duration: 0.25s;
    transition-timing-function: cubic-bezier(0.5, -0.5, 0.5, 1.5);
  }

  .toggle-icon {
    transition-delay: 0.125s;
  }

  .toggle-icon:before,
  .toggle-icon:after {
    transition-delay: 0s;
    transform-origin: center;
  }

  .burger-target.clicked + .nav-toggle .toggle-icon {
    transition-delay: 0s;
  }
  .burger-target.clicked + .nav-toggle .toggle-icon:before,
  .burger-target.clicked + .nav-toggle .toggle-icon:after {
    transition-delay: 0.125s;
  }
  .burger-target.clicked + .nav-toggle .toggle-icon:before {
    transform: translateY(10px) rotate(135deg);
  }
  .burger-target.clicked + .nav-toggle .toggle-icon {
    border-color: transparent;
  }
  .burger-target.clicked + .nav-toggle .toggle-icon:after {
    transform: translateY(-10px) rotate(-135deg);
  }
}
